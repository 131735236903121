import * as React from 'react';
import { useSelector } from 'react-redux';
import {
    DateFormatOptions,
    IntlProvider,
    LocalizationProvider,
    load,
    loadMessages,
    useInternationalization as useKendoInternationalization,
    useLocalization as useKendoLocalization
} from '@progress/kendo-react-intl';
import frNumbers from 'cldr-numbers-full/main/fr-CA/numbers.json';
import frLocalCurrency from 'cldr-numbers-full/main/fr-CA/currencies.json';
import frCaGregorian from 'cldr-dates-full/main/fr-CA/ca-gregorian.json';
import frDateFields from 'cldr-dates-full/main/fr-CA/dateFields.json';
import weekData from 'cldr-core/supplemental/weekData.json';

import { AppState } from '../types';

load(frNumbers, frLocalCurrency, frCaGregorian, frDateFields, weekData);

type StringDictionary = { [key: string]: string };

export const loadStrings = (enStrings: StringDictionary, frStrings: StringDictionary) => {
    loadMessages(enStrings, 'en');
    loadMessages(frStrings, 'fr');
};

export const useLocalization = () => {
    const localization = useKendoLocalization();
    const intl = useKendoInternationalization();
    return React.useCallback(
        (key: string, values?: any[]) => {
            const localized = localization.toLanguageString(key, key);
            return values ? intl.format(localized, values) : localized;
        },
        [intl, localization]
    );
};

export const useIntl = () => {
    const intl = useKendoInternationalization();
    const isFrench = useSelector<AppState, boolean>((s) => s.userSetup.isFrench);
    const dateFormat = useSelector<AppState, string>((s) => s.userSetup.dateFormat);

    const description = (english?: string, french?: string): string => {
        return isFrench && french && french.trim().length > 0 ? french : english ?? '';
    };

    const formatDate = (date: string | Date, format: string | DateFormatOptions) => {
        const value = date instanceof Date ? date : intl.parseDate(date);
        return intl.formatDate(value, format);
    };

    const formatMonthandYear = (date: string | Date, format: string | DateFormatOptions) => {
        const value = date instanceof Date ? date : intl.parseDate(date);
        return intl.formatDate(value, format);
    };

    return React.useMemo(
        () => ({
            ...intl,
            isFrench,
            description,
            formatNumber: intl.formatNumber,
            formatDate: (value: string | Date, format?: string | DateFormatOptions) =>
                formatDate(value, format ?? dateFormat),
            formatMonthandYear: (value: string | Date, format?: string | DateFormatOptions) =>
                formatMonthandYear(
                    value,
                    format ?? ({ month: 'long', year: 'numeric' } as DateFormatOptions)
                )
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [intl, dateFormat]
    );
};

export const GlobalizationProvider: React.FC = ({ children }) => {
    const isFrench = useSelector<AppState, boolean>((state) => state.userSetup.isFrench);
    const locale = isFrench ? 'fr-CA' : 'en-US';
    const language = isFrench ? 'fr' : 'en';
    return (
        <IntlProvider locale={locale}>
            <LocalizationProvider language={language}>{children}</LocalizationProvider>
        </IntlProvider>
    );
};
