import * as React from 'react';

import { ResolveTenant, resolveUrl } from '../helpers';
import { appConfig } from '../appConfig';
import { store } from '../store';

export function antiForgeryTokenHeader() {
    const tokenInput = document.getElementsByName('__RequestVerificationToken');
    if (tokenInput.length === 0) {
        return null;
    }
    return {
        name: 'RequestVerificationToken',
        value: (tokenInput[0] as HTMLInputElement).value
    };
}

export function useFetch() {
    const handleResponse = <T>(response: Response) => {
        let status: number;
        return new Promise<any | void>((resolve) => {
            status = response.status;
            if (status === 200 || status === 400 || status === 403) {
                return resolve(response.json());
            }
            return resolve(undefined);
        }).then((responseData: any) => {
            if (status >= 200 && status <= 299) {
                return Promise.resolve(<T>responseData);
            }
            if (status === 403) {
                return Promise.reject<T>(responseData);
            }
            if (responseData?.errors) {
                return Promise.reject(responseData.errors);
            }
            return Promise.reject();
        });
    };

    const tenant = ResolveTenant();

    const update = <T>(method: 'POST' | 'PUT' | 'DELETE', url: string, data?: any): Promise<T> => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept-Language', store.getState().userSetup?.isFrench ? 'fr-CA' : 'en-US');
        if (tenant) {
            headers.append('X-Tenant', tenant!);
        }
        if (appConfig.isDevelopment) {
            headers.append(
                'Strict-Transport-Security',
                'max-age=31536000; includeSubDomains; preload'
            );
        }

        const tokenHeader = antiForgeryTokenHeader();
        if (tokenHeader) {
            headers.append(tokenHeader.name, tokenHeader.value);
        }
        return fetch(resolveUrl(url), {
            method,
            headers,
            redirect: 'follow',
            referrerPolicy: 'no-referrer',
            body: data ? JSON.stringify(data) : undefined
        }).then<T>(handleResponse);
    };

    const get = <T>(url: string) => {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept-Language', store.getState().userSetup?.isFrench ? 'fr-CA' : 'en-US');
        if (tenant) {
            headers.append('X-Tenant', tenant!);
        }
        if (appConfig.isDevelopment) {
            headers.append(
                'Strict-Transport-Security',
                'max-age=31536000; includeSubDomains; preload'
            );
        }

        return fetch(resolveUrl(url), {
            method: 'GET',
            headers,
            redirect: 'follow',
            referrerPolicy: 'no-referrer'
        }).then<T>(handleResponse);
    };

    return React.useMemo(
        () => ({
            get,
            post: <T>(url: string, data?: any) => update<T>('POST', url, data),
            put: <T>(url: string, data?: any) => update<T>('PUT', url, data),
            del: <T>(url: string, data?: any) => update<T>('DELETE', url, data)
        }),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );
}
