import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => `
    *::-webkit-scrollbar {
      width: 8px;
    }
    *::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 8px rgba(0, 0, 0, 0);
    }
    *::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, .1);
    }
    ::-webkit-scrollbar {
      height: 8px;
    }
    ::-ms-reveal {
      display: none;
    }
    :root {
      --header-height: 64px;
      --sidenav-font-size: ${theme.typography.body2.fontSize};
      --sidenav-width--base: 300px;
      --sidenav-width--collapsed: 57px;
      --sidenav-width: var(--sidenav-width--base);
      --main-margin-left: var(--sidenav-width);
      --sidebar-transition-duration: ${theme.transitions.duration.enteringScreen}ms;
      --sidenav-text-padding: ${theme.spacing(2)}px;
    }
    
    /* React Material-UI */
    .MuiInputBase-input::selection {
      color: ${theme.palette.secondary.contrastText};
      background-color: ${theme.palette.secondary.main};
    }

    /* Kendo */
    .k-animation-container {
      z-index: ${theme.zIndex.modal + 1};
    }
    .k-widget, 
    .k-block, 
    .k-panel, 
    .k-combobox, 
    .k-dateinput, 
    .k-datepicker, 
    .k-input, 
    .k-maskedtextbox, 
    .k-multiselect, 
    .k-numerictextbox, 
    .k-window, 
    .k-pager-wrap, 
    .k-textbox, 
    .k-text-selection {
      &::selection {
        color: ${theme.palette.secondary.contrastText};
        background-color: ${theme.palette.secondary.main};
      }
    }
    .k-list {
      & .k-item.k-state-selected, & .k-item:hover.k-state-selected {
        color: ${theme.palette.primary[800]};
      }
    }
    .k-textbox-container, .k-floating-label-container {
      &::after {
        background-color: ${theme.palette.primary[800]};
      }
      &.k-state-focused > .k-label {
        color: ${theme.palette.primary[800]};
      }
    }
    .k-input:invalid {
      box-shadow: none;
    }
  `}
`;
