import * as React from 'react';
import { Provider } from 'react-redux';
import ReactGA from 'react-ga';

import { ActionTypes } from './store/user-setup';
import { ThemeProvider } from './styles/themes';
import { ErrorBoundary } from './shared/ErrorBoundary';
import { store } from './store';
import { Progress } from './shared';
import { Router } from './Router';
import { UserClass } from './types';
import { isNullOrWhitespace } from './helpers';

export const App: React.FC = () => {
    const { appConfig, userSetup, user } = window.avanti || {};

    if (!isNullOrWhitespace(appConfig.gaMeasurementId)) {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${appConfig.gaMeasurementId}`;
        script.async = true;
        document.head.appendChild(script);

        const configScript = document.createElement('script');
        configScript.type = 'text/javascript';
        const scriptText = `window.dataLayer = window.dataLayer || [];
                    function gtag() {
                      dataLayer.push(arguments);
                    }
                    window.gtag = gtag;
                    gtag('js', new Date());
                    gtag('config', '${appConfig.gaMeasurementId}', {
                      'ClientCode': '${appConfig.serialNo ?? ''}',
                      'UserClass': '${UserClass[user?.userClass!]}',
                      'Language': '${userSetup.isFrench ? 'French' : 'English'}',
                      'ProgramName': '',
                      'ProgramOption1': '',
                      'ProgramOption2': ''
                    });`;
        configScript.appendChild(document.createTextNode(scriptText));
        document.head.appendChild(configScript);
        ReactGA.initialize(appConfig.gaMeasurementId);
    }

    React.useLayoutEffect(() => {
        // read language from cookie to persist language selection
        const cookie = document.cookie.split(';').find((c) => c.includes('AvantiLanguageId'));
        let languageId = 0;
        if (cookie) {
            languageId = Number(cookie.split('=')[1]) || 0;
        } else {
            languageId = userSetup.isFrench ? 1 : 0;
        }
        store.dispatch({ type: ActionTypes.Language, payload: languageId > 0 });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [store]);

    return (
        <Provider store={store}>
            <ThemeProvider>
                <ErrorBoundary>
                    <React.Suspense fallback={<Progress />}>
                        <Router />
                    </React.Suspense>
                </ErrorBoundary>
            </ThemeProvider>
        </Provider>
    );
};
