import React from 'react';
import styled from 'styled-components';
import { Grid as MuiGrid } from '@material-ui/core';

import { ResolveTenant, resolveUrl } from '../../helpers';
import { LoginResetTimer } from '../../shared';

const MainLayout = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    // this is to make sure the layout is not too small on mobile
    @media (min-width: 768px) {
        min-height: 100%;
    }
`;

const LayoutContainer = styled.div`
    ${({ theme }) => `
    border-radius: ${theme.spacing(1)}px;
    box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    max-width: 1000px;
    width: 100%;

    @media (min-width: 600px) and (max-width: 899px) {
      width: fit-content;
      flex-direction: column;
      width: 65%;
    }

    @media (max-width: 599px) {
      flex-direction: column;
      margin-bottom: auto;
      margin-top: 2%;
    }
  `}
`;
const LogoContainer = styled.div`
    ${({ theme }) => `
  ${[theme.breakpoints.down('sm')]} {
    border-radius: ${theme.spacing(1, 1, 0, 0)};
  }
  flex-basis: 45%;
  border-radius: ${theme.spacing(1, 0, 0, 1)};
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.01), rgba(0, 0, 0, 0.01)), linear-gradient(0deg, rgba(74, 195, 181, 0.05), rgba(74, 195, 181, 0.05)), #F4F9F9;
  `}
`;

const InnerLogoContainer = styled.div`
    width: 100%;
    height: 100%;
    padding: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 500px;
    @media (max-width: 899px) {
        padding: 2%;
        height: 200px;
        min-height: auto;
    }
`;

const AvantiLogoImg = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding-left: 24px;
    padding-right: 24px;
`;

const Grid = styled(MuiGrid)`
    flex-basis: 55%;
    width: 100%;
    margin: 0;
    ${({ theme }) => `
    padding:  ${theme.spacing(5.5, 4)};
    @media (max-width: 899px) {
      padding:  ${theme.spacing(4, 2)};
      width: auto;
      margin: 0;
    }
  `}
`;

export const Layout: React.FC = (props) => {
    const { children } = props;

    const imgFallBackRef = React.useRef(false);
    const tenant = ResolveTenant();
    const companyLogoUrl = resolveUrl('/api/application/logo', tenant);
    const peopleLogoUrl = resolveUrl('img/login-people.svg');

    const handleImageError = (event?: React.SyntheticEvent<HTMLImageElement> | null) => {
        if (!event || !event.currentTarget) {
            return;
        }
        const { currentTarget } = event;
        const imgElement = currentTarget as HTMLImageElement;
        if (!imgFallBackRef.current) {
            imgFallBackRef.current = true;
            imgElement.src = peopleLogoUrl; // fallback to people logo
        }
        imgElement.onerror = null; // prevent infinite loop
    };

    return (
        <MainLayout>
            <LoginResetTimer />
            <LayoutContainer>
                <LogoContainer>
                    <InnerLogoContainer>
                        <AvantiLogoImg
                            src={companyLogoUrl}
                            alt="Company Logo"
                            data-testid="companyLogo"
                            onError={handleImageError}
                        />
                    </InnerLogoContainer>
                </LogoContainer>
                <Grid container spacing={2} justifyContent="flex-end" data-testid="pageContent">
                    {children}
                </Grid>
            </LayoutContainer>
        </MainLayout>
    );
};
