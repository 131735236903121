import * as React from 'react';
import { LinearProgress, useTheme } from '@material-ui/core';

import { useIntl } from '../hooks';

export const Progress = () => {
  const theme = useTheme();
  const intl = useIntl();

  return (
    <>
      <h1 className="visually-hidden">
        {intl.description('Loading, please wait...', 'Chargement en cours, veuillez patienter')}
      </h1>
      <LinearProgress style={{ margin: theme.spacing(2) * -1 }} />
    </>
  );
};
