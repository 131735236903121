import * as React from 'react';
import { connect } from 'react-redux';
import { Container, Typography } from '@material-ui/core';

import { AppState } from '../types';

type Info = { componentStack: string };

type Props = {
  // eslint-disable-next-line react/require-default-props
  isDev?: boolean;
  isFrench?: boolean;
  children: React.ReactNode;
};

type State = {
  error?: Error;
  info?: Info;
};

class ErrorBoundaryComponent extends React.Component<Props, State> {
  isDev: boolean;

  constructor(props: Props) {
    super(props);
    this.state = {};
    this.isDev = props.isDev ?? (!process.env.NODE_ENV || process.env.NODE_ENV === 'development');
  }

  componentDidCatch(error: Error, info: Info) {
    // Display fallback UI
    this.setState({ error, info });
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, info);
  }

  render() {
    const { children, isFrench } = this.props;
    const { error, info } = this.state;
    if (error) {
      return (
        <Container tabIndex={0}>
          {this.isDev && (
            <>
              <Typography component="h1" variant="h4" color="error" gutterBottom>
                {error.name}
              </Typography>
              <Typography component="h2" variant="h6">
                {error.message}
              </Typography>
              {error.stack && <pre style={{ marginBottom: 0 }}>{error.stack}</pre>}
              {info?.componentStack && (
                <pre style={{ marginBottom: 0 }}>{info.componentStack.trim()}</pre>
              )}
            </>
          )}
          {!this.isDev && (
            <Typography component="h1" variant="h4">
              {isFrench && (
                <>
                  Désolé, une erreur s&apos;est produite! Si le problème persiste veuillez contacter
                  support.
                </>
              )}
              {!isFrench && (
                <>
                  Sorry but something went wrong! If the problem persists, please contact support.
                </>
              )}
            </Typography>
          )}
        </Container>
      );
    }
    return children;
  }
}

const mapStateToProps = (state: AppState) => {
  return {
    isFrench: state.userSetup?.isFrench ?? false
  };
};

export const ErrorBoundary = connect(mapStateToProps)(ErrorBoundaryComponent);
