import '../styles/kendo-material.css';
import * as React from 'react';
import { CssBaseline, makeStyles } from '@material-ui/core';

import { GlobalStyles } from '../styles/global';

// #region styles

const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%'
    },
    mainContainer: {
        display: 'flex',
        height: '100%'
        // [theme.breakpoints.up('sm')]: {
        //   height: '100%'
        // },
        // [theme.breakpoints.up('md')]: {
        //   height: '100%'
        // }
    },
    main: {
        overflow: 'auto',
        flexGrow: 1,
        padding: theme.spacing(2),
        transition: `margin var(--sidebar-transition-duration) ${theme.transitions.easing.sharp}`
    }
}));

// #endregion

export const Layout: React.FC = (props) => {
    const { children } = props;
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <CssBaseline />
            <GlobalStyles />
            <div className={classes.mainContainer}>
                <main role="main" className={classes.main}>
                    {children}
                </main>
            </div>
        </div>
    );
};
