export enum UserClass {
    ClassA = 0,
    ClassB = 1,
    ClassC = 2,
    ClassD = 3,
    ClassX = 99
}

export enum Action {
    View = 1,
    Insert = 2,
    Modify = 3,
    Delete = 4,
    Cancel = 5
}

export enum MessageType {
    Default = 0,
    Success,
    Warning,
    Error
}
