import { LoginSession } from '../../shared/authentication/types';

export enum ActionTypes {
    Session = 'LOGIN_SESSION'
}

type SetLoginSessionStore = {
    type: typeof ActionTypes.Session;
    payload: LoginSession;
};

export type LoginSessionStateActions = SetLoginSessionStore;
