/* eslint-disable no-plusplus */
export function ToNumberWithLeadingZeros(value: string, minSize: number) {
    let newNum = value;
    for (let i = newNum.length; i < minSize; i++) {
        newNum = `0${newNum}`;
    }
    return newNum as unknown as number;
}

/*
 * Returns true if the string is empty or only contains whitespace.
 */
export const isWhitespace = (str: string) => str.trim().length === 0;

/*
 * Returns true if the string is not defined, empty or only contains whitespace.
 */
export const isNullOrWhitespace = (str?: string | null) => !str || str.trim().length === 0;

/*
 * Returns french string if it exists and if isFrench is true. Else return english text
 */
export const toFrench = (
    value: string | null | undefined,
    valueFr: string | null | undefined,
    isFrench: Boolean
): string => {
    if (isFrench) {
        if (!isNullOrWhitespace(valueFr)) {
            return valueFr!;
        }
    }
    return value!;
};
