export enum ActionTypes {
  DateFormat = 'DATE_FORMAT',
  Language = 'LANGUAGE',
  Theme = 'THEME'
}

type SetDateFormatAction = {
  type: typeof ActionTypes.DateFormat;
  payload: string;
};

type SetLanguage = {
  type: typeof ActionTypes.Language;
  payload: boolean;
};

type SetThemeAction = {
  type: typeof ActionTypes.Theme;
  payload: number;
};

export type UserSetupStateActions = SetDateFormatAction | SetLanguage | SetThemeAction;
