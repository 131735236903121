import Cookies from 'js-cookie';
import { useSelector } from 'react-redux';
import { AppState } from 'types';

const tenantFromAcrValues = (values: string): string | null => {
    const map = new Map<string, string>();
    const decodedValues = decodeURIComponent(values);
    const splitValues = decodedValues.split(' ');
    splitValues.forEach((val) => {
        const data = val.split(':');
        if (data.length === 2) {
            map.set(data[0], data[1]);
        }
    });

    return map.has('tenant') ? map.get('tenant')! : null;
};

export const getTenantFromReturnUrl = (returnUrl: string): string | null => {
    const acrValuesText = 'acr_values';

    const acrValuesIndex = returnUrl.indexOf(acrValuesText);
    if (acrValuesIndex === -1) {
        return null;
    }

    const endIndex = returnUrl.indexOf('&', acrValuesIndex);
    const acrValues =
        endIndex === -1
            ? returnUrl.substring(acrValuesIndex + acrValuesText.length + 1)
            : returnUrl.substring(acrValuesIndex + acrValuesText.length + 1, endIndex);

    return tenantFromAcrValues(acrValues);
};

export function ResolveTenant(): string | null {
    let tenant = useSelector<AppState, string | null>((s) => s.tenant);
    if (tenant) {
        return tenant;
    }

    tenant = Cookies.get('tenant') as string;
    if (tenant) {
        return tenant;
    }

    const searchParams = new URLSearchParams(window.location.search);
    const returnUrl = searchParams.get('ReturnUrl');
    if (returnUrl) {
        return getTenantFromReturnUrl(returnUrl);
    }

    const acrValues = searchParams.get('acr_values');
    if (acrValues) {
        return tenantFromAcrValues(acrValues);
    }

    return null;
}
