/* eslint-disable */
import './styles/index.css';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { App } from './App';

declare let __webpack_public_path__: string;
__webpack_public_path__ = window.avanti.appConfig.baseUrl;

if (process.env.NODE_ENV !== 'production') {
    if (process.env.WDYR === '1') {
      console.log('Loading why-did-you-render');
      const whyDidYouRender = require('@welldone-software/why-did-you-render');
      whyDidYouRender(React, {
        trackAllPureComponents: true,
        trackExtraHooks: [[require('react-redux/lib'), 'useSelector']]
      });
    }
  
    if (process.env.AXE !== '0') {
      console.log('Loading react-axe');
      const axe = require('react-axe');
      var context = {
        exclude: [['#__bs_notify__']]
      };
      axe(React, ReactDOM, 1000, null, context);
    }
  }

ReactDOM.render(<App />, document.getElementById('app'));
