import * as React from 'react';
import Cookies from 'js-cookie';
import { useHistory, useLocation } from 'react-router-dom';

import { useService } from '../authentication/service';
import { LoginUrl } from '../types';

export const LoginResetTimer: React.FC = () => {
    const history = useHistory();
    const service = useService();
    const location = useLocation();

    const [loginUrl, setLoginUrl] = React.useState<LoginUrl | undefined>(undefined);

    const reset = () => {
        const path = window.location.pathname.toLowerCase();
        if (
            !(
                path.startsWith('/login') ||
                path.startsWith('/authenticator') ||
                path.startsWith('/securityquestion') ||
                path.startsWith('/companyselection')
            )
        ) {
            return;
        }

        const resetValue = Cookies.get('_ls');
        if (!resetValue) {
            return;
        }

        const resetTime = parseInt(resetValue);
        if (!Number.isFinite(resetTime) || resetTime <= 0) {
            return;
        }

        const date = new Date();
        const utcDate = Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds()
        );

        if (resetTime > utcDate) {
            return;
        }

        history.push({
            pathname: '/LoggedOut',
            state: { loginExpired: true, loginUrl: loginUrl?.url }
        });
    };

    React.useEffect(() => {
        const interval = setInterval(() => reset(), 5000);

        return () => clearInterval(interval);
    });

    React.useEffect(() => {
        const path = location.pathname.toLowerCase();
        if (!path.startsWith('/login')) {
            return;
        }

        const returnUrl = new URLSearchParams(location.search).get('ReturnUrl');
        if (!returnUrl) {
            return;
        }

        service.getLoginUrl({ returnUrl }).then((value) => {
            setLoginUrl(value);
        });
    }, [location, service]);

    return <></>;
};
