import { appConfig } from '../appConfig';

export const resolveUrl = (url: string, tenant?: string | null) => {
    let resolvedURL = url;
    if (resolvedURL.startsWith('/')) {
        resolvedURL = resolvedURL.slice(1);
    }

    const fixedUrl = appConfig.baseUrl + resolvedURL + (tenant ? `?tenant=${tenant}` : '');
    return fixedUrl;
};

export const resolveLogoutUrl = (
    tenant?: string | null,
    isOnboardingUrl: boolean = false,
    logoutRedirectUri?: string
) => {
    if (logoutRedirectUri) {
        return logoutRedirectUri;
    }

    const defaultUrl = `../`;
    if (!tenant) {
        return defaultUrl;
    }

    const urlObject = new URL(window.location.toString());
    const { host } = urlObject;
    const hostPart = host.split('.');

    if (hostPart.length !== 3) {
        return defaultUrl;
    }

    hostPart[0] = tenant!;
    const updatedHost = hostPart.join('.');

    urlObject.host = updatedHost;
    urlObject.pathname = '/';
    urlObject.search = '';

    const updatedUrl = urlObject.toString();
    return isOnboardingUrl ? `${updatedUrl}onboardingLogin` : updatedUrl;
};
