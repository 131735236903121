import { combineReducers, createStore as createReduxStore } from 'redux';

import { AppState, UserSetup } from '../types';
import { userSetupReducer } from './user-setup/reducer';
import { loginSessionReducer } from './login-session/reducer';
import { tenantReducer } from './tenant/reducer';

function createStore(userSetup: UserSetup) {
    const initialState: AppState = {
        userSetup,
        loginSession: undefined,
        tenant: null
    };

    // This will allow us to use redux DevTools extensions to dispatch actions and review store in development
    /* eslint-disable no-underscore-dangle */
    const reduxDevTools =
        process.env.NODE_ENV !== 'production'
            ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
            : undefined;

    return createReduxStore(
        combineReducers({
            userSetup: userSetupReducer,
            loginSession: loginSessionReducer,
            tenant: tenantReducer
        }),
        initialState as any,
        reduxDevTools
    );
}

const defaultUserSetup: UserSetup = {
    isFrench: false,
    dateFormat: 'DD-MMM-YYYY',
    theme: 0
};

const { userSetup } = window.avanti || {};
const store = createStore(userSetup ?? defaultUserSetup);
export { store };
