import { AppConfig as AppConfigType } from './types';
/*
 * A simple wrapper class to expose appConfig properties as read-only.
 */
class AppConfig implements AppConfigType {
    // Convert to private field when targetting ES6 #config = ...
    private config?: AppConfigType;

    constructor() {
        try {
            this.config = window.avanti.appConfig;
        } catch {
            // Called from the service worker.
        }
    }

    get isDevelopment() {
        return this.config?.isDevelopment ?? false;
    }

    get baseUrl() {
        return this.config?.baseUrl ?? '';
    }

    get gaMeasurementId() {
        return this.config?.gaMeasurementId ?? '';
    }

    get serialNo() {
        return this.config?.serialNo ?? '';
    }
}

export const appConfig = new AppConfig();
