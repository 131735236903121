import { LoginSession } from '../../authentication/types';
import { ActionTypes, LoginSessionStateActions } from './types';

export function loginSessionReducer(
  state: LoginSession | null = null,
  action: LoginSessionStateActions
): LoginSession | null {
  switch (action.type) {
    case ActionTypes.Session: {
      const session = action.payload;
      return session;
    }
    default:
      return state;
  }
}
