import * as React from 'react';
import { BrowserRouter, Route, RouteProps, Switch } from 'react-router-dom';

import { appConfig } from './appConfig';
import { GlobalizationProvider } from './hooks';
import { Layout } from './layout/Layout';
import { ErrorBoundary, Progress } from './shared';
import { Layout as AuthServiceLayout } from './authentication';
import { Home } from './home/Home';
import { NotFound } from './error/NotFound';

export const Router: React.FC = () => {
    const Authenticator = React.lazy(() =>
        import(
            /* webpackChunkName: "authenticator" */
            './authentication/authenticator/Authenticator'
        ).then(({ Authenticator: AuthenticatorComponent }) => ({ default: AuthenticatorComponent }))
    );

    const CompanySelection = React.lazy(() =>
        import(
            /* webpackChunkName: "company-selection" */
            './authentication/CompanySelection'
        ).then(({ CompanySelection: CompanySelectionComponent }) => ({
            default: CompanySelectionComponent
        }))
    );

    const CompleteRegistration = React.lazy(() =>
        import(
            /* webpackChunkName: "complete-registration" */
            './authentication/register/CompleteRegistration'
        ).then(({ CompleteRegistration: CompleteRegistrationComponent }) => ({
            default: CompleteRegistrationComponent
        }))
    );

    const LoggedOut = React.lazy(() =>
        import(
            /* webpackChunkName: "logged-out" */
            './authentication/logout/LoggedOut'
        ).then(({ LoggedOut: LoggedOutComponent }) => ({ default: LoggedOutComponent }))
    );

    const Login = React.lazy(() =>
        import(
            /* webpackChunkName: "login" */
            './authentication/Login'
        ).then(({ Login: LoginComponent }) => ({ default: LoginComponent }))
    );

    const Logout = React.lazy(() =>
        import(
            /* webpackChunkName: "logout" */
            './authentication/logout/Logout'
        ).then(({ Logout: LogoutComponent }) => ({ default: LogoutComponent }))
    );

    const NewPassword = React.lazy(() =>
        import(
            /* webpackChunkName: "new-password" */
            './authentication/password/NewPassword'
        ).then(({ NewPassword: NewPasswordComponent }) => ({
            default: NewPasswordComponent
        }))
    );

    const Register = React.lazy(() =>
        import(
            /* webpackChunkName: "registration" */
            './authentication/register/RegisterBase'
        ).then(({ RegisterBase: RegisterBaseComponent }) => ({
            default: RegisterBaseComponent
        }))
    );

    const ResetPassword = React.lazy(() =>
        import(
            /* webpackChunkName: "reset-password" */
            './authentication/password/ResetPassword'
        ).then(({ ResetPassword: ResetPasswordComponent }) => ({ default: ResetPasswordComponent }))
    );

    const SecurityQuestion = React.lazy(() =>
        import(
            /* webpackChunkName: "security-question" */
            './authentication/securityQuestion/SecurityQuestion'
        ).then(({ SecurityQuestion: SecurityQuestionComponent }) => ({
            default: SecurityQuestionComponent
        }))
    );
    const SetupAuthenticator = React.lazy(() =>
        import(
            /* webpackChunkName: "setup-authenticator" */
            './authentication/authenticator/SetupAuthenticator'
        ).then(({ SetupAuthenticator: SetupAuthenticatorComponent }) => ({
            default: SetupAuthenticatorComponent
        }))
    );
    const SetupSecurityQuestions = React.lazy(() =>
        import(
            /* webpackChunkName: "setup-security-question" */
            './authentication/securityQuestion/SetupSecurityQuestions'
        ).then(({ SetupSecurityQuestions: SetupSecurityQuestionsComponent }) => ({
            default: SetupSecurityQuestionsComponent
        }))
    );

    const TokenValidation = React.lazy(() =>
        import(
            /* webpackChunkName: "token-validation" */
            './authentication/password/TokenValidation'
        ).then(({ TokenValidation: TokenValidationComponent }) => ({
            default: TokenValidationComponent
        }))
    );

    const { baseUrl } = appConfig;

    return (
        <GlobalizationProvider>
            <BrowserRouter basename={baseUrl}>
                <Layout>
                    <ErrorBoundary>
                        <React.Suspense fallback={<Progress />}>
                            <Switch>
                                <RouteWrapper key="login" path="/Login" component={Login} />
                                <RouteWrapper
                                    key="companySelection"
                                    path="/CompanySelection"
                                    component={CompanySelection}
                                />
                                <RouteWrapper
                                    key="setupSecurityQuestion"
                                    path="/SecurityQuestion/Maintain"
                                    component={SetupSecurityQuestions}
                                />
                                <RouteWrapper
                                    key="validateSecurityQuestion"
                                    path="/SecurityQuestion"
                                    component={SecurityQuestion}
                                />
                                <RouteWrapper
                                    key="setupAuthenticator"
                                    path="/Authenticator/Setup"
                                    component={SetupAuthenticator}
                                />
                                <RouteWrapper
                                    key="authenticator"
                                    path="/Authenticator"
                                    component={Authenticator}
                                />
                                <RouteWrapper
                                    key="passwordReset"
                                    path="/Password/Reset"
                                    component={ResetPassword}
                                />
                                <RouteWrapper
                                    key="registerComplete"
                                    path="/Register/Complete"
                                    component={CompleteRegistration}
                                />
                                <RouteWrapper
                                    key="register"
                                    exact
                                    path="/Register"
                                    component={Register}
                                />
                                <RouteWrapper
                                    key="tokenValidation"
                                    path="/Password/TokenValidation"
                                    component={TokenValidation}
                                />
                                <RouteWrapper
                                    key="newPassword"
                                    path="/Password/New"
                                    component={NewPassword}
                                />
                                <RouteWrapper key="logout" path="/Logout" component={Logout} />
                                <RouteWrapper
                                    key="loggedOut"
                                    path="/LoggedOut"
                                    component={LoggedOut}
                                />
                                <RouteWrapper key="home" path="/" exact component={Home} />
                                <RouteWrapper key="404" component={NotFound} />
                            </Switch>
                        </React.Suspense>
                    </ErrorBoundary>
                </Layout>
            </BrowserRouter>
        </GlobalizationProvider>
    );
};

type Props = {
    component: any;
} & RouteProps;
const RouteWrapper: React.FC<Props> = ({ component: Component, ...rest }) => {
    return (
        <AuthServiceLayout>
            <Route {...rest} render={(props) => <Component {...props} />} />
        </AuthServiceLayout>
    );
};
