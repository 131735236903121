import { UserSetup } from '../../types';
import { ActionTypes, UserSetupStateActions } from './types';

export function userSetupReducer(
  state: UserSetup | null = null,
  action: UserSetupStateActions
): UserSetup | null {
  if (!state) {
    return null;
  }
  switch (action.type) {
    case ActionTypes.DateFormat: {
      const dateFormat = action.payload;
      return { ...state, dateFormat };
    }
    case ActionTypes.Language: {
      const isFrench = action.payload;
      return { ...state, isFrench };
    }
    case ActionTypes.Theme: {
      const theme = action.payload;
      return { ...state, theme };
    }
    default:
      return state;
  }
}
