import * as React from 'react';

import { LoginSession } from '../shared/authentication/types';
import { useFetch } from '../hooks/fetch';
import {
    AuthenticatorKeys,
    AuthenticatorPinInput,
    CompanyLoginInput,
    LoggedOutModel,
    LoginInput,
    PasswordChangeInput,
    PasswordSettings,
    RegistrationFields,
    RegistrationInput,
    RegistrationInputLegacy,
    RegistrationValidationInput,
    ResetPasswordSetup,
    SecurityAnswerInput,
    SecurityQuestion,
    SecurityQuestionSetup,
    TokenInput,
    UserSecurityQuestion
} from './types';
import { CompleteRegistrationResult, LoginSetup, LoginUrl, LogoutInfo } from '../types';

export const useService = () => {
    const fetch = useFetch();
    return React.useMemo(
        () => ({
            addQuestion: (data: UserSecurityQuestion) =>
                fetch.post<number>(`/api/SecurityQuestions/AddUserSecurityQuestion`, data),
            changePassword: (data: PasswordChangeInput) =>
                fetch.post(`/api/authentication/ChangePassword`, data),
            completeRegister: (data: RegistrationValidationInput) =>
                fetch.post<CompleteRegistrationResult>(
                    `/api/authentication/CompleteRegistration`,
                    data
                ),
            deleteQuestion: (questionId: number) =>
                fetch.post<AuthenticatorKeys>(
                    `/api/SecurityQuestions/DeleteUserSecurityQuestion/${questionId}`
                ),
            getAuthenticatorKeys: () =>
                fetch.get<AuthenticatorKeys>(`/api/Authenticator/GenerateKey`),
            getPasswordSettings: () =>
                fetch.get<PasswordSettings>(`/api/Application/PasswordSettings`),

            getSecurityQuestionsSetup: (lang: string) =>
                fetch.get<SecurityQuestionSetup>(`/api/SecurityQuestions/Setup/${lang}`),
            getRecaptchaSettings: () => fetch.get<boolean>(`/api/Application/RecaptchaSettings`),
            getIsUseRecaptchaDuringRegistration: () =>
                fetch.get<boolean>(`/api/Application/IsUseRecaptchaDuringRegistration`),
            getRegistrationFields: () =>
                fetch.get<RegistrationFields[]>(`/api/Authentication/RegistrationFields`),
            getUserSecurityQuestions: () =>
                fetch.get<UserSecurityQuestion[]>(`/api/SecurityQuestions/UserSecurityQuestions`),
            getUserSecurityQuestionForValidation: () =>
                fetch.get<SecurityQuestion[]>(
                    `/api/SecurityQuestions/UserSecurityQuestionsForValidation`
                ),
            getUserCompanies: (data: CompanyLoginInput) =>
                fetch.post<LoginSession>(`/api/Authentication/UserCompanies`, data),
            loggedOut: (data: { logoutId: string; isOnboarding: boolean }) =>
                fetch.post<LoggedOutModel>(`/api/Authentication/LoggedOut`, data),
            logIn: (data: CompanyLoginInput) =>
                fetch.post<LoginSession>(`/api/Authentication/LogIn`, data),
            getLoginSetup: (data: { returnUrl: string }) =>
                fetch.post<LoginSetup>(`/api/Application/Login`, data),
            getLoginUrl: (data: { returnUrl: string }) =>
                fetch.post<LoginUrl>(`/api/Application/LoginUrl`, data),
            getLogout: (data: { logoutId: string; isOnboarding: boolean }) =>
                fetch.post<LogoutInfo>(`/api/Authentication/GetLogout`, data),
            logout: (data: { logoutId: string; isOnboarding: boolean }) =>
                fetch.post<string>(`/api/Authentication/Logout`, data),
            recoverUsername: (email: string) =>
                fetch.post<{ [key: string]: string }>(`/api/authentication/Register/${email}`),
            register: (data: RegistrationInput) =>
                fetch.post<RegistrationValidationInput>(`/api/authentication/Register`, data),
            registerLegacy: (data: RegistrationInputLegacy) =>
                fetch.post<RegistrationValidationInput>(`/api/authentication/RegisterLegacy`, data),
            resetPassword: (id: string, data: { [key: number]: string }) =>
                fetch.post<{ [key: string]: string }>(
                    `/api/User/SendResetPasswordEmail/${id}`,
                    data
                ),
            getResetPasswordSetup: (isOnboarding: boolean) =>
                fetch.get<ResetPasswordSetup>(
                    `/api/User/ResetPasswordSetup?isOnboarding=${isOnboarding}`
                ),
            saveAuthenticatorPin: (pin: number) =>
                fetch.post<boolean>(`/api/Authenticator/SaveKey/${pin}`),
            signIn: (data: LoginInput) =>
                fetch.post<LoginSession>(`/api/Authentication/SignIn`, data),
            validateAuthenticatorPin: (pinInput: AuthenticatorPinInput) =>
                fetch.post<boolean>(`/api/Authenticator/Validate`, pinInput),
            validateToken: (data: TokenInput) =>
                fetch.post<{ [key: string]: string }>(`/api/User/ValidateToken`, data),
            validateSecurityAnswers: (data: SecurityAnswerInput) =>
                fetch.post<boolean>(`/api/SecurityQuestions/ValidateAnswers`, data)
        }),
        [fetch]
    );
};
