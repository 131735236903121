import { ActionTypes, TenantStateActions } from './types';

export function tenantReducer(
  state: string | null = null,
  action: TenantStateActions
): string | null {
  switch (action.type) {
    case ActionTypes.Tenant: {
      const tenant = action.payload;
      return tenant;
    }
    default:
      return state;
  }
}
