import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  ThemeProvider as MuiThemeProvider,
  StylesProvider,
  createTheme as createMuiTheme
} from '@material-ui/core';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';

import { AppState } from '../types';

export const palette = {
  primary: {
    50: '#E5F4F3',
    100: '#BFE4E0',
    200: '#94D2CB',
    300: '#69C0B6',
    400: '#48B3A7',
    main: '#28A597',
    600: '#249D8F',
    700: '#1E9384',
    800: '#1C8575',
    900: '#0F7969',
    A100: '#ACFFF1',
    A200: '#79FFE8',
    A400: '#2CFFDB'
  },
  secondary: {
    50: '#E3F0F4',
    100: '#B9D8E4',
    200: '#8BBFD3',
    300: '#5DA5C1',
    400: '#3A91B3',
    main: '#177EA6',
    600: '#14769E',
    700: '#116B95',
    800: '#0D618B',
    900: '#074E7B',
    A100: '#AADAFF',
    A200: '#77C4FF',
    A400: '#2AA3FF',
    contrastText: '#FFFFFF'
  },
  success: {
    main: '#1C8575'
  },
  info: {
    main: '#3097BF'
  },
  error: {
    50: '#FBE6E6',
    100: '#F5C2C2',
    200: '#EF9999',
    300: '#E87070',
    400: '#E35151',
    main: '#DE3232',
    600: '#DA2D2D',
    700: '#D52626',
    800: '#D11F1F',
    900: '#C81313',
    A100: '#FFF8F8',
    A200: '#FFC5C5',
    A400: '#FF7979'
  },
  warning: {
    50: '#FDF4E5',
    100: '#FBE3BE',
    200: '#F9D092',
    300: '#F6BD66',
    400: '#F4AE46',
    main: '#F2A025',
    600: '#F09821',
    700: '#EE8E1B',
    800: '#EC8416',
    900: '#E8730D',
    A100: '#FFFFFF',
    A200: '#FFEDE0',
    A400: '#FFC194'
  },
  grey: {
    50: '#EBEBEB',
    100: '#CECECE',
    200: '#AEAEAE',
    300: '#8D8D8D',
    400: '#747474',
    500: '#5C5C5C',
    600: '#545454',
    700: '#4A4A4A',
    800: '#414141',
    900: '#303030',
    A100: '#d5d5d5',
    A200: '#aaaaaa',
    A400: '#303030',
    A700: '#616161'
  },
  text: {
    primary: 'rgba(0,0,0,0.64)',
    secondary: 'rgba(0,0,0,0.54)',
    tertiary: 'rgba(0,0,0,0.70)',
    disabled: 'rgba(0,0,0,0.22)',
    hint: 'rgba(0,0,0,0.38)'
  },
  action: {
    active: '#3F3F3F',
    disabled: '#C5C5C5'
  },
  common: {
    black: '#000000',
    white: '#FFFFFF'
  },
  divider: 'rgba(0,0,0,0.12)'
};

export const layoutPalette = {
  appBarBg: {
    primary: palette.grey[50],
    secondary: palette.secondary.main
  },
  appBarColor: {
    primary: palette.text.primary,
    secondary: palette.secondary.contrastText
  },
  drawerBg: {
    primary: palette.grey[50],
    secondary: palette.grey[500]
  },
  drawerColor: {
    primary: palette.text.primary,
    secondary: palette.common.white
  },
  borderColor: {
    primary: palette.divider,
    secondary: palette.grey[400]
  }
};

const fontWeights = {
  regular: 400,
  semibold: 600,
  bold: 700
};

const baseFontSize = 16;

export const typography = {
  fontFamily: 'Open Sans, sans-serif',
  fontSize: 14,
  fontWeightRegular: fontWeights.regular,
  fontWeightMedium: fontWeights.semibold,
  fontWeightBold: fontWeights.bold,
  h1: {
    fontSize: `${60 / baseFontSize}rem`,
    fontWeight: fontWeights.bold,
    letterSpacing: '-0.00833em',
    lineHeight: 1.2
  },
  h2: {
    fontWeight: fontWeights.semibold,
    fontSize: `${48 / baseFontSize}rem`,
    letterSpacing: '0em',
    lineHeight: 1.235
  },
  h3: {
    fontWeight: fontWeights.semibold,
    fontSize: `${36 / baseFontSize}rem`,
    letterSpacing: '0.00735em',
    lineHeight: 1.43
  },
  h4: {
    fontSize: `${29 / baseFontSize}rem`,
    fontWeight: fontWeights.bold,
    letterSpacing: '0em',
    lineHeight: 1.334
  },
  h5: {
    fontSize: `${24 / baseFontSize}rem`,
    fontWeight: fontWeights.semibold,
    letterSpacing: '0em',
    lineHeight: 1.334
  },
  h6: {
    fontSize: `${20 / baseFontSize}rem`,
    fontWeight: fontWeights.semibold,
    letterSpacing: '0.0075em',
    lineHeight: 1.6
  },
  subtitle1: {
    fontSize: `${16 / baseFontSize}rem`,
    fontWeight: fontWeights.semibold,
    lineHeight: 1.5
  },
  subtitle2: {
    fontSize: `${14 / baseFontSize}rem`,
    fontWeight: fontWeights.semibold,
    lineHeight: 1.43
  },
  body1: {
    fontSize: `${16 / baseFontSize}rem`,
    fontWeight: fontWeights.regular,
    lineHeight: 1.5
  },
  body2: {
    fontSize: `${14 / baseFontSize}rem`,
    fontWeight: fontWeights.regular,
    lineHeight: 1.43
  },
  button: {
    fontSize: `${14 / baseFontSize}rem`,
    fontWeight: fontWeights.semibold
  },
  caption: {
    fontSize: `${12 / baseFontSize}rem`,
    fontWeight: fontWeights.regular
  }
};

export const defaultTheme = createMuiTheme({
  props: {
    MuiButton: {
      disableElevation: true
    }
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontWeight: fontWeights.regular
        }
      }
    },
    MuiFab: {
      root: {
        '&$disabled': {
          backgroundColor: palette.grey[50]
        }
      }
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: palette.primary[800]
      }
    },
    MuiChip: {
      root: {
        backgroundColor: palette.grey[50],
        color: palette.text.primary,
        '& $avatar': {
          backgroundColor: palette.grey[100],
          boxShadow: 'none'
        }
      },
      outlinedPrimary: {
        color: palette.primary[700]
      },
      outlinedSecondary: {
        color: palette.secondary[700]
      },
      icon: {
        color: palette.text.primary
      },
      clickable: {
        '&:hover, &:focus': {
          backgroundColor: palette.grey[100]
        }
      }
    }
  },
  palette,
  shape: {
    borderRadius: 4
  },
  typography
});

export const darkTheme = createMuiTheme({
  palette: {
    primary: {
      main: '#FF00FF'
    },
    secondary: {
      main: '#EE82EE'
    }
  }
});

export const ThemeProvider: React.FC = ({ children }) => {
  const currentTheme = useSelector((state: AppState) => state.userSetup.theme) ?? 0;
  let theme;
  switch (currentTheme) {
    case 1:
      theme = darkTheme;
      break;
    default:
      theme = defaultTheme;
      break;
  }
  return (
    <StylesProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
      </MuiThemeProvider>
    </StylesProvider>
  );
};
